import React from "react";
import theme from "theme";
import { Theme, Text, Box, Button, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Актив Драйв
			</title>
			<meta name={"description"} content={"Керуй майбутнім вже сьогодні!"} />
			<meta property={"og:title"} content={"Головна | Актив Драйв"} />
			<meta property={"og:description"} content={"Керуй майбутнім вже сьогодні!"} />
			<meta property={"og:image"} content={"https://quantixarex.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quantixarex.com/images/car-department-of-motor-vehicles-computer-icons-intermediary-blue-driving.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quantixarex.com/images/car-department-of-motor-vehicles-computer-icons-intermediary-blue-driving.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quantixarex.com/images/car-department-of-motor-vehicles-computer-icons-intermediary-blue-driving.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quantixarex.com/images/car-department-of-motor-vehicles-computer-icons-intermediary-blue-driving.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quantixarex.com/images/car-department-of-motor-vehicles-computer-icons-intermediary-blue-driving.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quantixarex.com/images/car-department-of-motor-vehicles-computer-icons-intermediary-blue-driving.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 200px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						padding="7px 20px 7px 20px"
						margin="0px 0px 30px 0px"
						border-radius="25px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						sm-flex-direction="column"
						lg-width="fit-content"
					>
						<Text margin="0px 10px 0px 0px" color="#8b9197" font="--base" sm-margin="0px 0 5px 0px">
						Ваш шлях до досконалості починається тут 
						</Text>
					</Box>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
					Актив Драйв
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
					Ласкаво просимо, майбутні водії! В автошколі Актив Драйв ми з радістю допоможемо вам стати впевненим та кваліфікованим водієм. Незалежно від того, чи ви вперше сідаєте за кермо, чи хочете освіжити свої навички водіння, ви знайшли правильне місце. Наші доброзичливі, професійні інструктори та інноваційні методи навчання забезпечують доброзичливе та продуктивне навчальне середовище для всіх.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 50px 12px 50px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--primary"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Звʼязатися
						</Button>
					</Box>
				</Box>
				<Image
					src="https://quantixarex.com/images/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://quantixarex.com/images/2.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					md-height="500px"
					sm-height="400px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
					Чому варто обрати Актив Драйв?
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						margin="50px 0px 0px 0px"
						grid-gap="16px 24px"
						sm-grid-template-columns="1fr"
					>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								color="--light"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									1
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
							Персоналізовані траєкторії навчання: Кожен студент унікальний, як і наш підхід до навчання. Індивідуальні уроки гарантують, що ви будете розвиватися у власному темпі.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								color="--light"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									2
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
							Підвищення кваліфікації з техніки безпеки: Безпека - наш головний пріоритет. Вивчіть новітні техніки безпечного водіння, щоб убезпечити себе на дорозі.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									3
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
							Гнучкий графік: Ми розуміємо, що життя насичене. Саме тому ми пропонуємо гнучкі варіанти розкладу, які відповідають вашому стилю життя.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								align-items="center"
								color="--light"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									4
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
							Конкурентоспроможні ціни: Отримайте максимальну віддачу від ваших інвестицій у навчання водінню без шкоди для якості.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});